/**
 * Created by wangtong on 2022/2/7
 */


import {ApiV1Service, RequestConfig, Post, Get} from "@/api/base";
import * as models from "@/models"

class GroupService extends ApiV1Service {
	@Get('/group/camp/share_data')
	async getCampShareData(payload: models.GetGroupCampShareDataRequest, config?: RequestConfig): Promise<models.GroupCampShareData> {
		return this.request(payload, config)
	}
	
	@Get('/group/battle/share_data')
	async getBattleShareData(payload: models.GetGroupBattleShareDataRequest, config?: RequestConfig): Promise<models.GroupBattleShareData> {
		return this.request(payload, config)
	}
}

export const GroupApi = new GroupService()

