/**
 * Created by wangtong on 2022/2/7
 */
import {createUserData, UserData} from "@/models/user";
import {DynamicCommentData} from "@/models/dynamic_comment";
import {createGroupData, GroupData} from "@/models/group";

export  interface DynamicShareRecord  {
	id: number
	create_time: number // 创建时间
	update_time: number // 更新时间
	delete_time: number // 删除时间
	deleted: number // 是否删除
	share_mode: string
	url: string // 分享链接
	user_id: number // 分享用户ID
	dynamic_id: number // 分享动态ID
	visit_count: number // 访问次数
	download_android_app_count: number
	download_ios_app_count: number
	open_android_app_count: number
	open_ios_app_count: number
	channel: string
	platform: number
	version: number
	version_name: string
	brand: string
	model: string
	route: string
	share_image_path: string
	share_image_width: number
	share_image_height: number
	share_image_size: number
	share_image_format: string
	share_image_md5: string
	ip: string
	suspended: number
	suspend_expire_time: number
	title: string
	text: string
}
export function createDynamicShareRecord():DynamicShareRecord{
	return {
		brand: "",
		channel: "",
		create_time: 0,
		delete_time: 0,
		deleted: 0,
		download_android_app_count: 0,
		download_ios_app_count: 0,
		dynamic_id: 0,
		id: 0,
		ip: "",
		model: "",
		open_android_app_count: 0,
		open_ios_app_count: 0,
		platform: 0,
		route: "",
		share_image_format: "",
		share_image_height: 0,
		share_image_md5: "",
		share_image_path: "",
		share_image_size: 0,
		share_image_width: 0,
		share_mode: "",
		suspend_expire_time: 0,
		suspended: 0,
		update_time: 0,
		url: "",
		user_id: 0,
		version: 0,
		version_name: "",
		visit_count: 0,
		title: '',
		text: ''
	}
}
export  interface DynamicShareData  {
	record: DynamicShareRecord
	dynamic: DynamicData
}
export function createDynamicShareData(): DynamicShareData{
	return {
		record: createDynamicShareRecord(),
		dynamic: createDynamicData()
	}
}

export  interface GetDynamicShareDataRequest  {
	share_id: string
}



// DynamicInfo
// 动态内容：图片、视频、文字、语音
export interface DynamicInfo  {
	id: number
	create_time: number // 创建时间
	update_time: number // 更新时间
	delete_time: number // 删除时间
	deleted: number // 是否删除
	create_user_id: number // 动态创建用户ID
	content: string // 动态内容
	title: string // 动态标题
	create_manager_id: number // 动态创建管理员ID
	type: number // 动态类型 常量: 动态类型 DynamicType
	suspend_expire_time: number // 封禁过期时间
	like_count: number // 用户点赞数
	comment_count: number // 评论数
	comment_user_count: number // 评论用户数
	tag_count: number // 标签数
	uid: number // 动态UID
	sid: string // 动态散弹号
	share_group_id: number // 分享的群组ID
	share_count: number // 分享数
	suspended: number // 是否封禁
	video_play_user_count: number // 视频播放用户量
	video_play_count: number // 视频播放量
	audio_play_count: number // 音频播放量
	audio_play_user_count: number // 音频播放用户量
	read_count: number // 动态详情浏览量
	read_user_count: number // 动态详情浏览量
	scroll_count: number // 滚动过次数
	scroll_user_count: number // 滚动过的用户数
	comment_count_depth0: number // 深度为0的评论数
	reported_count: number // 被举报次数
	collected_count: number // 被收藏次数
	create_user_nickname: string
	create_user_avatar: string
	create_user_level: number
	create_user_rank: number
	create_user_keyboard_points: number
	create_user_is_vip: number
	create_user_sign: string
	create_user_star: number
}

export interface DynamicImage  {
	id: number // 动态ID
	index: number // 索引
	path: string // 图片路径
	width: number // 图片宽度
	height: number // 图片高度
	size: number // 图片大小
	format: string // 图片格式
	md5: string
}

export interface DynamicDataOption  {
	is_like: number
	comments: number
	is_follow_create_user: number
	is_fan_create_user: number
	remark_name: number
	is_collect: number
}

export interface DynamicVideo  {
	id: number // ID
	path: string // 视频相对路径
	duration: number // 视频时长，单位ms
	width: number // 视频宽度
	height: number // 视频高度
	format: string // 视频格式
	size: number // 视频大小
	angle: number // 视频角度 0/90/180/270
	mirror: number // 是否是镜像
	md5: string // MD5
	snapshot_path: string // 视频预览图相对路径
	snapshot_format: string // 视频预览图片格式
	snapshot_size: number // 视频预览图片大小
	snapshot_md5: string // MD5
	vod_id: number
	vod_url: string // 视频点播视频链接
	vod_width: number
	vod_height: number
	vod_format: string
	vod_size: number
}


export interface DynamicAudio  {
	id: number // 动态ID
	path: string // 音频路径
	size: number // 音频大小/kb
	format: string // 音频格式
	duration: number // 音频时长/ms
}

export interface DynamicData  {
	info: DynamicInfo
	images?: DynamicImage[]
	video: DynamicVideo
	audio: DynamicAudio
	create_user: UserData // 创建用户数据
	comments?: DynamicCommentData[] // 部分预览的评论
	is_like: boolean // 当前用户是否点赞了
	share_group: GroupData
	data_option: DynamicDataOption
	pool_id: string // 推荐池ID
	is_collect: boolean // 是否收藏
}

export function createDynamicInfo(): DynamicInfo {
	return {
		audio_play_count: 0,
		audio_play_user_count: 0,
		collected_count: 0,
		comment_count: 0,
		comment_count_depth0: 0,
		comment_user_count: 0,
		content: "",
		create_manager_id: 0,
		create_time: 0,
		create_user_avatar: "",
		create_user_id: 0,
		create_user_is_vip: 0,
		create_user_keyboard_points: 0,
		create_user_level: 0,
		create_user_nickname: "",
		create_user_rank: 0,
		create_user_sign: "",
		create_user_star: 0,
		delete_time: 0,
		deleted: 0,
		id: 0,
		like_count: 0,
		read_count: 0,
		read_user_count: 0,
		reported_count: 0,
		scroll_count: 0,
		scroll_user_count: 0,
		share_count: 0,
		share_group_id: 0,
		sid: "",
		suspend_expire_time: 0,
		suspended: 0,
		tag_count: 0,
		title: "",
		type: 0,
		uid: 0,
		update_time: 0,
		video_play_count: 0,
		video_play_user_count: 0
	}
}

export function createDynamicData(): DynamicData {
	return {
		audio: {
			duration: 0, format: "", id: 0, path: "", size: 0
		},
		comments: [],
		create_user: createUserData(),
		data_option: {
			comments: 0, is_collect: 0, is_fan_create_user: 0, is_follow_create_user: 0, is_like: 0, remark_name: 0
		},
		images: [],
		info: createDynamicInfo(),
		is_collect: false,
		is_like: false,
		pool_id: "",
		share_group: createGroupData(),
		video: {
			angle: 0,
			duration: 0,
			format: "",
			height: 0,
			id: 0,
			md5: "",
			mirror: 0,
			path: "",
			size: 0,
			snapshot_format: "",
			snapshot_md5: "",
			snapshot_path: "",
			snapshot_size: 0,
			vod_format: "",
			vod_height: 0,
			vod_id: 0,
			vod_size: 0,
			vod_url: "",
			vod_width: 0,
			width: 0
		}
	}
}
export {};
