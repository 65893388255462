/**
 * Created by wangtong on 2022/2/10
 */

export interface AuthQuery {
	app_access_token: string
	app_user_id: string
	app_platform: string
	app_version: string
	app_version_name: string
	app_channel: string
	app_model: string
	app_brand: string
}

export function createAuthQuery(): AuthQuery {
	return {
		app_brand: "",
		app_model: "",
		app_access_token: "",
		app_channel: "",
		app_platform: "",
		app_user_id: "",
		app_version: "",
		app_version_name: ""
	}
}

export {};
