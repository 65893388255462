









/**
 * Created by wangtong on 2022/2/7
 */
import {Component, Vue, Prop, Watch} from "vue-property-decorator";
import {PropType} from 'vue'
import {createDynamicShareData, createGroupBattleShareData, DynamicShareData, GroupBattleShareData} from "@/models";
import {DynamicApi} from "@/api/dynamic";
import {GroupApi} from "@/api/group";
import ShareCard from "@/components/ShareCard.vue";
import BasePage from "@/mixins/BasePage";
import MobileAppDownloader from "@/components/app_downloader/MobileAppDownloader.vue";
import PcAppDownloader from "@/components/app_downloader/PcAppDownloader.vue";
import GroupBattleShareCard from "@/components/share/GroupBattleShareCard.vue";

@Component({
    components: {GroupBattleShareCard, PcAppDownloader, MobileAppDownloader, ShareCard}
})
export default class GroupBattleSharePage extends BasePage {
    @Prop() id!: any

    shareData: GroupBattleShareData = createGroupBattleShareData()

    async created() {
        super.created()
        this.shareData = await GroupApi.getBattleShareData({share_id: this.id})
    }

    get appSchema(): string {
        return `sandan:///app/group/battle/chat?groupId=${this.shareData.record.group_id}&invite_id=${this.shareData.record.invite_id}`
    }

}
