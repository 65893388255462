/**
 * Created by wangtong on 2022/2/7
 */
import {createUserData, UserData, UserJoinGroupRel} from "@/models/user";

export interface GroupBattleShareRecord {
	id: number
	create_time: number // 创建时间
	update_time: number // 更新时间
	delete_time: number // 删除时间
	deleted: number // 是否删除
	share_mode: string
	url: string // 分享链接
	user_id: number // 用户ID
	group_id: number // 群组ID
	visit_count: number // 访问次数
	invite_id: number
	download_android_app_count: number
	download_ios_app_count: number
	open_android_app_count: number
	open_ios_app_count: number
	channel: string
	platform: number
	version: number
	version_name: string
	brand: string
	model: string
	route: string
	share_image_path: string
	share_image_width: number
	share_image_height: number
	share_image_size: number
	share_image_format: string
	share_image_md5: string
	ip: string
	suspended: number
	suspend_expire_time: number
	title: string
	text: string
}

export function createGroupBattleShareRecord(): GroupBattleShareRecord {
	return {
		brand: "",
		channel: "",
		create_time: 0,
		delete_time: 0,
		deleted: 0,
		download_android_app_count: 0,
		download_ios_app_count: 0,
		group_id: 0,
		id: 0,
		invite_id: 0,
		ip: "",
		model: "",
		open_android_app_count: 0,
		open_ios_app_count: 0,
		platform: 0,
		route: "",
		share_image_format: "",
		share_image_height: 0,
		share_image_md5: "",
		share_image_path: "",
		share_image_size: 0,
		share_image_width: 0,
		share_mode: "",
		suspend_expire_time: 0,
		suspended: 0,
		update_time: 0,
		url: "",
		user_id: 0,
		version: 0,
		version_name: "",
		visit_count: 0,
		title: '',
		text: ''
	}
}

export interface GroupBattleShareData {
	record: GroupBattleShareRecord
	invite_data: GroupInviteData
}

export function createGroupBattleShareData(): GroupBattleShareData {
	return {
		record: createGroupBattleShareRecord(),
		invite_data: createGroupInviteData()
	}
}

export interface GroupCampShareRecord {
	id: number
	create_time: number // 创建时间
	update_time: number // 更新时间
	delete_time: number // 删除时间
	deleted: number // 是否删除
	share_mode: string
	url: string // 分享链接
	user_id: number // 用户ID
	group_id: number // 群组ID
	visit_count: number // 访问次数
	download_android_app_count: number
	download_ios_app_count: number
	open_android_app_count: number
	open_ios_app_count: number
	channel: string
	platform: number
	version: number
	version_name: string
	brand: string
	model: string
	route: string
	share_image_path: string
	share_image_width: number
	share_image_height: number
	share_image_size: number
	share_image_format: string
	share_image_md5: string
	ip: string
	suspended: number
	suspend_expire_time: number
	title: string
	text: string
}

export function createGroupCampShareRecord(): GroupCampShareRecord {
	return {
		brand: "",
		channel: "",
		create_time: 0,
		delete_time: 0,
		deleted: 0,
		download_android_app_count: 0,
		download_ios_app_count: 0,
		group_id: 0,
		id: 0,
		ip: "",
		model: "",
		open_android_app_count: 0,
		open_ios_app_count: 0,
		platform: 0,
		route: "",
		share_image_format: "",
		share_image_height: 0,
		share_image_md5: "",
		share_image_path: "",
		share_image_size: 0,
		share_image_width: 0,
		share_mode: "",
		suspend_expire_time: 0,
		suspended: 0,
		update_time: 0,
		url: "",
		user_id: 0,
		version: 0,
		version_name: "",
		visit_count: 0,
		title: '',
		text: ''
	}
}

export interface GroupCampShareData {
	record: GroupCampShareRecord
	group: GroupData
}

export function createGroupCampShareData(): GroupCampShareData {
	return {
		record: createGroupCampShareRecord(),
		group: createGroupData()
	}
}

export interface GetGroupCampShareDataRequest {
	share_id: string
}

export interface GetGroupBattleShareDataRequest {
	share_id: string
}

export interface GroupInfo  {
	id: number
	create_time: number // 创建时间
	update_time: number // 更新时间
	delete_time: number // 删除时间
	deleted: number // 是否删除
	name: string // 群名称
	type: number // 群类型 常量: 群类型 GroupType
	sid: string // 群散弹号
	detail: string // 群简介
	create_user_id: number // 创建用户ID
	suspend_expire_time: number // 封禁过期时间
	create_manager_id: number // 群组创建管理员ID
	battle_stage: number // 对线群生命周期阶段 常量: 对线群生命周期阶段 GroupBattleStage
	member_count: number // 群成员数
	onlooker_count: number // 围观用户数
	quit_count: number // 退出的群用户数
	player_count: number // 玩家数
	im_ok: number // IM是否同步成功
	tag_count: number // 标签数
	suspended: number // 是否被封禁
	division: number // 群组段位层次 常量: 群组段位层次 GroupDivisionLevel
	reported_count: number
	collected_count: number
	battle_start_time: number // 从准备阶段到对线阶段的时间
	first_battle_start_time: number // 第一次从准备阶段到对线阶段的时间
	battle_duration: number // 对线持续时长
	apply_join_option: number // 常量: 大本营加群申请选项 GroupCampApplyJoinOption
	apply_join_question: string // 加群问题
	battle_settle_time: number // 对线结算时间
	battle_finish_time: number // 对线结束时间
	victory_state: number // 群胜负状态 常量: 群胜负状态 GroupBattleVictoryState
	last_from_message_id: number // 上一次统计的开始消息ID
	last_to_message_id: number // 上一次统计的结束消息ID
	battle_expire_time: number // 预期对线过期时间
	restart_from_group_id: number // 重启来源群ID
	message_count: number // 总消息数
	battle_protection_expire_time: number // 对线群预期保护期结束时间
}

export interface GroupBg  {
	id: number // 群ID
	path: string // 图片路径
	width: number // 图片宽度
	height: number // 图片高度
	size: number // 图片大小
	format: string // 图片格式
	md5: string
}

export interface GroupTeamInfo  {
	id: number // 群ID
	side: number // 战队方 常量: 群战队方 GroupTeamSide
	create_time: number // 创建时间
	update_time: number // 更新时间
	name: string // 战队名称
	score: number // 战队分值（总分值）
	player_count: number // 战队玩家数
	player_count_level: number // 战队玩家数级别
	player_count_upper_limit: number // 战队玩家数上限
	player_count_lower_limit: number // 战队玩家数下限
	game_point_1: number // 第一个胜利节点 常量: 对线群胜利节点类型 GroupBattleGamePointType
	game_point_1_red_score: number // 第一个胜利节点达到时的红方比分
	game_point_1_blue_score: number // 第一个胜利节点达到时的蓝方比分
	game_point_2: number // 第二个胜利节点
	game_point_2_red_score: number // 第二个胜利节点达到时的红方分数
	game_point_2_blue_score: number // 第二个胜利节点达到时的蓝方分数
	game_point_3: number // 第三个胜利节点
	game_point_3_red_score: number // 第三个胜利节点达到时的红方分数
	game_point_3_blue_score: number // 第三个胜利节点达到时的蓝方分数
	game_point_1_time: number // 获得第一个节点时间
	game_point_2_time: number // 获得第二个节点时间
	game_point_3_time: number // 获得第三个节点时间
	energy: number // 导弹能量
	like_score: number // 获赞得分
	prop_score: number // 道具得分
	invite_score: number // 邀请得分
	message_score: number // 消息得分
	message_count: number // 总消息数
}

// GroupData
// 大本营有群头像、群封面
export interface GroupData  {
	info: GroupInfo
	avatar: GroupAvatar
	bg: GroupBg
	red_team: GroupTeamData // 红方战队
	blue_team: GroupTeamData // 蓝方战队
	join_rel: UserJoinGroupRel
	is_lock: boolean // 大本营是否被锁住了
	recent_msg?: GroupRecentMsg[]
	data_option?: GroupDataOption
	pool_id: string // 推荐池ID
	is_collect: boolean
}

export interface GroupAvatar  {
	id: number // 群ID
	path: string // 图片相对路径
	width: number // 图片宽度
	height: number // 图片高度
	format: string // 图片格式
	size: number // 图片大小
	md5: string // MD5
}

export interface GroupTeamData  {
	info: GroupTeamInfo
	avatar: GroupAvatar
}

export interface GroupTeamAvatar  {
	id: number // 群组ID
	side: number // 战队方 常量: 群战队方 GroupTeamSide
	path: string // 图片相对路径
	width: number // 图片宽度
	height: number // 图片高度
	format: string // 图片格式
	size: number // 图片大小
	md5: string // MD5
}

export interface GroupMemberData  {
	user?: UserData
	join_rel?: UserJoinGroupRel
}

export interface GroupRecentMsg  {
	id: number
	sender_id: number
	sender_avatar: string
	sender_show_name: string
	group_id: number
	text: string
	team_side: number
	local_id: number
	unique_key: string
}

export interface GroupInviteCode  {
	code: string // 邀请码
	group_id: number // 群组ID
	team_side: number // 常量: 群战队方 GroupTeamSide
	create_user_id: number // 创建用户ID
	id: number // 邀请ID
}

export function createGroupInviteCode(): GroupInviteCode {
	return {
		code: "", create_user_id: 0, group_id: 0, id: 0, team_side: 0
	}
}
export interface GroupInviteData  {
	invite_code: GroupInviteCode // 邀请码
	user: UserData // 邀请人
	group: GroupData // 邀请群组
}

export function createGroupInviteData(): GroupInviteData {
	return {
		invite_code: createGroupInviteCode(),
		user: createUserData(),
		group: createGroupData()
	}
}

export interface GroupBattleStatus  {
	red_score: number // 红色战队总比分
	blue_score: number // 蓝色战队比分
	red_player_count: number // 红方玩家数量
	blue_player_count: number // 蓝方玩家数量
	member_count: number // 总成员数量（包括围观用户）
	battle_stage: number // 常量: 对线群生命周期阶段 GroupBattleStage
	red_game_point_1: number // 红方第一个节点
	red_game_point_1_red_score: number // 红方拿到第一个节点时的红方比分
	red_game_point_1_blue_score: number // 红方拿到第一个节点时的蓝方比分
	red_game_point_2: number // 红方第二个节点
	red_game_point_2_red_score: number // 红方第二个节点时的红方比分
	red_game_point_2_blue_score: number // 红方第二个节点时的蓝方比分
	red_game_point_3: number // 红方第三个节点
	red_game_point_3_red_score: number // 红方第三个节点时的红方比分
	red_game_point_3_blue_score: number // 红方第三个节点时的蓝方比分
	blue_game_point_1: number
	blue_game_point_1_red_score: number
	blue_game_point_1_blue_score: number
	blue_game_point_2: number
	blue_game_point_2_red_score: number
	blue_game_point_2_blue_score: number
	blue_game_point_3: number
	blue_game_point_3_red_score: number
	blue_game_point_3_blue_score: number
	red_game_point_1_time: number // 红方获得第一个节点时间
	red_game_point_2_time: number // 红方获得第二个节点时间
	red_game_point_3_time: number // 红方获得第三个节点时间
	blue_game_point_1_time: number // 蓝方获得第一个节点时间
	blue_game_point_2_time: number // 蓝方获得第二个节点时间
	blue_game_point_3_time: number // 蓝方获得第三个节点时间
	red_energy: number // 红方能量
	blue_energy: number // 蓝方能量
	first_battle_start_time: number // 第一次从准备阶段到对线阶段的时间
	battle_start_time: number // 从准备阶段到对线阶段的时间
	battle_duration: number // 对线持续时长累计
	battle_finish_time: number // 对线结束时间
	battle_settle_time: number // 对线结算时间
	red_message_score: number // 红方发言得分
	blue_message_score: number // 蓝方发言得分
	red_invite_score: number // 红方拉人得分
	blue_invite_score: number // 蓝方拉人得分
	red_like_score: number // 红方获赞得分
	blue_like_score: number // 蓝方获赞得分
	red_prop_score: number // 红方道具得分
	blue_prop_score: number // 蓝方道具得分
	last_from_message_id: number // 上一次统计的起始消息ID
	last_to_message_id: number // 上一次统计的结束消息ID
	red_player_count_level: number
	red_player_count_lower_limit: number
	red_player_count_upper_limit: number
	blue_player_count_level: number
	blue_player_count_lower_limit: number
	blue_player_count_upper_limit: number
	victory_state: number // 胜负状态 常量: 群胜负状态 GroupBattleVictoryState
	battle_expire_time: number // 预期对线过期时间
	red_message_count: number
	blue_message_count: number
	battle_protection_expire_time: number
}




export interface GroupBattleConfig  {
	id: number
	msg_score: number // 文本消息比分
	image_score: number // 图片消息比分
	video_score: number // 视频消息比分
	audio_score: number // 语音消息比分
	like_score: number // 点赞比分
	crit_score: number // 暴击比分
	invite_player_score: number // 邀请玩家比分
	invite_onlooker_score: number // 邀请围观者比分
	anti_spam_min_send_interval: number // 对线群内最小发言时间间隔
	anti_spam_self_same_text_interval: number // 对线群自己最小相同发言时间间隔
	anti_spam_self_same_text_max_count: number // 对线群自己最小相同发言间隔内的最大次数
	anti_spam_team_same_text_interval: number // 对线群单边最小相同发言时间间隔
	anti_spam_team_same_text_max_count: number // 对线群单边相同发言时间间隔内的最大次数
	less_player_side_score_ratio: number // 人数较少方的分数补偿系数
	anti_spam_min_send_image_interval: number // 对线群内最小发送图片时间间隔
	anti_spam_min_send_video_interval: number // 对线群内最小发送视频时间间隔
	anti_spam_min_send_audio_interval: number // 对线群内最小发送语音时间间隔
	anti_spam_self_same_image_interval: number // 对线群自己最小发送相同图片时间间隔
	anti_spam_self_same_image_max_count: number // 对线群自己最小相同发送图片间隔内的最大次数
	anti_spam_team_same_image_interval: number // 对线群单边最小发送相同图片时间间隔
	anti_spam_team_same_image_max_count: number // 对线群单边最小相同发送图片间隔内的最大次数
	anti_spam_self_same_video_interval: number // 对线群自己最小发送相同视频时间间隔
	anti_spam_self_same_video_max_count: number // 对线群自己最小相同发送视频间隔内的最大次数
	anti_spam_team_same_video_interval: number // 对线群单边最小发送相同视频时间间隔
	anti_spam_team_same_video_max_count: number // 对线群单边最小相同发送视频间隔内的最大次数
	anti_spam_self_same_audio_interval: number // 对线群自己最小发送相同语音时间间隔
	anti_spam_self_same_audio_max_count: number // 对线群自己最小相同发送语音间隔内的最大次数
	anti_spam_team_same_audio_interval: number // 对线群单边最小相同发送语音间隔内的最大次数
	anti_spam_team_same_audio_max_count: number // 对线群单边最小相同发送语音间隔内的最大次数
}

export interface GroupSystemNotificationUserPreparePayload  {
	user_id?: number // 用户ID
	team_side?: number // 战队
}

export interface GroupTeamDataOption  {
	avatar: boolean
}

export interface GroupDataOption  {
	avatar: boolean
	bg: boolean
	team: boolean
	join_rel: number
	is_lock: number
	recent_msg: boolean
	team_data_option: GroupTeamDataOption
}

export interface GroupCampSendMessageRequest  {
	group_id?: number // 群组ID
	sender_avatar?: string
	sender_show_name?: string
	sender_level?: number
	sender_rank?: number
	sender_star?: number
	id?: number // 客户端自定义ID
	sender_is_vip?: number
}



export interface GroupChatOutlineData  {
	group_id: number // 群组ID
	unread_count: number // 未读消息计数
	is_mention_me: number // 是否at了我
	apply_join_count: number // 申请入群的申请数
}

export interface GroupBattleDenyJoinData  {
	expire_time: number
}

export interface GroupShutUpData  {
	user_id?: number
	expire_time?: number // 禁言截止时间
}

export function createGroupTeamData(): GroupTeamData {
	return {
		avatar: {
			format: "", height: 0, id: 0, md5: "", path: "", size: 0, width: 0
		},
		info: {
			create_time: 0,
			energy: 0,
			game_point_1: 0,
			game_point_1_blue_score: 0,
			game_point_1_red_score: 0,
			game_point_1_time: 0,
			game_point_2: 0,
			game_point_2_blue_score: 0,
			game_point_2_red_score: 0,
			game_point_2_time: 0,
			game_point_3: 0,
			game_point_3_blue_score: 0,
			game_point_3_red_score: 0,
			game_point_3_time: 0,
			id: 0,
			invite_score: 0,
			like_score: 0,
			message_count: 0,
			message_score: 0,
			name: "",
			player_count: 0,
			player_count_level: 0,
			player_count_lower_limit: 0,
			player_count_upper_limit: 0,
			prop_score: 0,
			score: 0,
			side: 0,
			update_time: 0
		}
	}
}

export function createGroupInfo(): GroupInfo  {
	return {
		apply_join_option: 0,
		apply_join_question: "",
		battle_duration: 0,
		battle_expire_time: 0,
		battle_finish_time: 0,
		battle_protection_expire_time: 0,
		battle_settle_time: 0,
		battle_stage: 0,
		battle_start_time: 0,
		collected_count: 0,
		create_manager_id: 0,
		create_time: 0,
		create_user_id: 0,
		delete_time: 0,
		deleted: 0,
		detail: "",
		division: 0,
		first_battle_start_time: 0,
		id: 0,
		im_ok: 0,
		last_from_message_id: 0,
		last_to_message_id: 0,
		member_count: 0,
		message_count: 0,
		name: "",
		onlooker_count: 0,
		player_count: 0,
		quit_count: 0,
		reported_count: 0,
		restart_from_group_id: 0,
		sid: "",
		suspend_expire_time: 0,
		suspended: 0,
		tag_count: 0,
		type: 0,
		update_time: 0,
		victory_state: 0
	}
}


export function createGroupData() : GroupData {
	return {
		avatar: {
			format: "", height: 0, id: 0, md5: "", path: "", size: 0, width: 0
		},
		bg: {
			format: "", height: 0, id: 0, md5: "", path: "", size: 0, width: 0
		},
		blue_team: createGroupTeamData(),
		info: createGroupInfo(),
		is_collect: false,
		is_lock: false,
		join_rel: {
			create_time: 0,
			delete_time: 0,
			deleted: 0,
			group_id: 0,
			invite_id: 0,
			invite_time: 0,
			is_pin: 0,
			is_player: 0,
			join_time: 0,
			msg_accept_option: 0,
			nickname: "",
			onlook_time: 0,
			play_time: 0,
			remark_name: "",
			role: 0,
			shut_up_expire_time: 0,
			team_side: 0,
			update_time: 0,
			user_id: 0
		},
		pool_id: "",
		red_team: createGroupTeamData()
	}
}
export {};
